import { Alert, AlertProps, AlertTitle } from "@mui/material";
import React from "react";

const MeasureInfoCard = React.forwardRef<HTMLDivElement, AlertProps>(({ title, children, ...alertProps }, ref) => (
    <Alert severity="info" {...alertProps} ref={ref}>
        {title != null ? <AlertTitle>{title}</AlertTitle> : null}
        {children}
    </Alert>
));
export default MeasureInfoCard;
